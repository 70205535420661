import { SubscriptionsTypes } from '@strim/gateway-api';

import { UpperCaseSnake } from '@rikstv/play-common/src/utils/types/typeUtils';

export type PackageState = SubscriptionsTypes.States;

export type PackageStateList = readonly PackageState[];

type PackageStateRecord = {
  readonly [key in PackageState as `${UpperCaseSnake<key>}`]: key;
};

export const packageStates: PackageStateRecord = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  CANCELED_DUE_TO_LACK_OF_PAYMENT: 'CanceledDueToLackOfPayment',
  PAST_DUE: 'PastDue',
  PENDING_PAYMENT_INFO: 'PendingPaymentInfo',
  PAYMENT_IN_PROGRESS: 'PaymentInProgress',
  UPGRADE_IN_PROGRESS: 'UpgradeInProgress',
  FREE_ACCESS: 'FreeAccess',
  PAYMENT_FAILED: 'PaymentFailed',
  PENDING_CANCELLATION: 'PendingCancellation',
} as const;

export const CONTENT_ACCESS_STATES: PackageStateList = [
  packageStates.ACTIVE,
  packageStates.FREE_ACCESS,
  packageStates.PENDING_CANCELLATION,
  packageStates.UPGRADE_IN_PROGRESS,
];

export const SUBSCRIPTION_ENDED_STATES: PackageStateList = [
  packageStates.CANCELED,
  packageStates.CANCELED_DUE_TO_LACK_OF_PAYMENT,
  packageStates.PAYMENT_FAILED,
];

export const WAITING_STATES: PackageStateList = [packageStates.PAYMENT_IN_PROGRESS, packageStates.UPGRADE_IN_PROGRESS];
