/**
 * URLs from the CMS that we need to hardcode somewhere else
 */
const strimetips = '/strimetips';
const strommetjenester = '/strommetjenester';
const subscription = '/abonnementer';

export const webAppLinks = {
  cmsFullStreamingPakke: '/streaming/full-tv-streaming-pakke',
  cmsEliteserien: '/streaming/sport/eliteserien',
  cmsPremierLeague: '/streaming/sport/premier-league',
  ledigeStillinger: '/hva-er-strim/jobb-hos-oss',
  hvaErStrim: '/hva-er-strim/',
  tvPakker: '/tv-pakker/',
  streaming: '/streaming/',
  rimeligere: '/hva-er-strim/rimeligere',
  strimErfaringer: '/hva-er-strim/strim-erfaringer',
  hvaErStreaming: '/hva-er-strim/hva-er-streaming',
  cmsFornoydeKunder: '/hva-er-strim/norges-mest-fornoyde-tv-kunder',
  kampanjeHost2022: '/kampanje/strommestotte',
  boxingDay: '/streaming/sport/boxing-day',
  forhandlinger: '/kunde/forhandlinger',

  hjelp: '/hjelp',
  hjelpMax: '/hjelp/hva-kan-jeg-se-med-strim/ofte-stilte-sporsmal-om-max',
  hjelpTv2Play: '/hjelp/hva-kan-jeg-se-med-strim/ofte-stilte-sporsmal-om-tv2play',
  hjelpViaplay: '/hjelp/hva-kan-jeg-se-med-strim/ofte-stilte-sporsmal-om-viaplay',
  hjelpSkyShowtime: '/hjelp/hva-kan-jeg-se-med-strim/ofte-stilte-sporsmal-om-skyshowtime',
  hjelpNordiskFilm: '/strommetjenester/nordisk-film-pluss',
  hjelpNrkTv: '/hjelp/hva-kan-jeg-se-med-strim',
  hjelpEnheterApper: '/hjelp/enheter-og-apper',

  /**
   * Tips shortcuts on frontpage
   */
  strimetips,

  /**
   * Strømmetjenester
   */
  strommetjenester,
  strommetjenesterNordiskfilm: `${strommetjenester}/nordisk-film-pluss`,
  strommetjenesterSkyShowtime: `${strommetjenester}/skyshowtime`,
  strommetjenesterVsport: `${strommetjenester}/v-sport`,
  strommetjenesterMax: `${strommetjenester}/max`,
  strommetjenesterViaplay: `${strommetjenester}/viaplay`,
  strommetjenesterNrk: `${strommetjenester}/nrk-tv`,
  strommetjenesterTv2play: `${strommetjenester}/tv-2-play`,
  strommetjenesterTv2sport: `${strommetjenester}/tv-2-sport`,
  strommetjenesterEurosport: `${strommetjenester}/eurosport`,

  subscriptionStrimLitt: `${subscription}/litt`,
  subscriptionStrimMindre: `${subscription}/mindre`,
  subscriptionStrimMye: `${subscription}/mye`,
  subscriptionStrimMest: `${subscription}/mest`,
  subscriptionStrimMyeSport: `${subscription}/mye-sport-basis`,
  subscriptionStrimMestSport: `${subscription}/mest-sport-basis`,
  subscriptionStrimMyeSportPremium: `${subscription}/mye-sport-premium`,
  subscriptionStrimMestSportPremium: `${subscription}/mest-sport-premium`,
  // Sportskanaler
  subscriptionStrimMyeSportskanaler: `${subscription}/mye-sportskanaler`,
  subscriptionStrimMestSportskanaler: `${subscription}/mest-sportskanaler`,
  // Viaplay Total
  subscriptionStrimMyeViaplayTotal: `${subscription}/mye-viaplay-total`,
  subscriptionStrimMestViaplayTotal: `${subscription}/mest-viaplay-total`,
} as const;
