import { lazy } from 'react';

import { AppRoutes, ROUTE_TYPE_NORMAL, ROUTE_TYPE_RAW } from '@rikstv/play-common/src/router/routes.type';

import { LayoutDark } from '../containers/layout/LayoutDark';
import { LayoutDarker } from '../containers/layout/LayoutDarker';

import { routerPaths } from './router.path';

const AccountPage = lazy(() =>
  import('../../domain/account/containers/AccountPage').then(module => ({ default: module.AccountPage }))
);
const ChangeEmailPage = lazy(() =>
  import('../../domain/account/containers/AccountInfoPage/ChangeEmailPage').then(module => ({
    default: module.ChangeEmailPage,
  }))
);
const ChangePhoneNumberPage = lazy(() =>
  import('../../domain/account/containers/AccountInfoPage/ChangePhoneNumberPage').then(module => ({
    default: module.ChangePhoneNumberPage,
  }))
);
const ChangePasswordPage = lazy(() =>
  import('../../domain/password/containers/ChangePasswordPage').then(module => ({
    default: module.ChangePasswordPage,
  }))
);
const ResetPasswordPage = lazy(() =>
  import('../../domain/password/containers/ResetPasswordPage').then(module => ({
    default: module.ResetPasswordPage,
  }))
);
const ConfirmCancelPackagePage = lazy(() =>
  import('../../domain/packages/containers/confirmCancelPackagePage/ConfirmCancelPackagePage').then(module => ({
    default: module.ConfirmCancelPackagePage,
  }))
);
const ConfirmedPrewinbackOffer = lazy(() =>
  import('../../domain/packages/containers/confirmCancelPackagePage/PrewinbackOffer/ConfirmedPrewinbackOffer').then(
    module => ({
      default: module.ConfirmedPrewinbackOffer,
    })
  )
);
const StopTrialPeriodPage = lazy(() =>
  import('../../domain/packages/containers/StopTrialPeriodPage/StopTrialPeriodPage').then(module => ({
    default: module.StopTrialPeriodPage,
  }))
);
const PackageUpgradeReceipt = lazy(() =>
  import('../../domain/packages/containers/receipts/Upgrade').then(module => ({
    default: module.UpgradeReceipt,
  }))
);
const PackageDowngradeReceipt = lazy(() =>
  import('../../domain/packages/containers/receipts/Downgrade').then(module => ({
    default: module.DowngradeReceipt,
  }))
);
const PackageDowngradeAbortedReceipt = lazy(() =>
  import('../../domain/packages/containers/receipts/DowngradeAborted').then(module => ({
    default: module.DowngradeAbortedReceipt,
  }))
);
const PackageActivatedReceipt = lazy(() =>
  import('../../domain/packages/containers/receipts/Activated').then(module => ({
    default: module.ActivatedReceipt,
  }))
);
const PackageNewReceipt = lazy(() =>
  import('../../domain/packages/containers/receipts/NewSubscription').then(module => ({
    default: module.NewSubscriptionReceipt,
  }))
);
const PackageCancelReceipt = lazy(() =>
  import('../../domain/packages/containers/receipts/Cancel').then(module => ({
    default: module.CancelReceipt,
  }))
);
const ChangePaymentMethod = lazy(() =>
  import('../../domain/payment/ChangePaymentMethod/ChangePaymentMethod').then(module => ({
    default: module.ChangePaymentMethod,
  }))
);
const PaymentPage = lazy(() =>
  import('../../domain/payment/containers/PaymentPage').then(module => ({ default: module.PaymentPage }))
);
const VippsReturnPage = lazy(() =>
  import('../../domain/payment/containers/VippsReturnPage').then(module => ({
    default: module.VippsReturnPage,
  }))
);
const ActivateViaplayPage = lazy(() =>
  import('../../domain/activateExternalService/containers/ActivateViaplayPage').then(module => ({
    default: module.ActivateViaplayPage,
  }))
);
const ActivateTV2PlayPage = lazy(() =>
  import('../../domain/activateExternalService/containers/ActivateTV2PlayPage').then(module => ({
    default: module.ActivateTV2PlayPage,
  }))
);
const ActivateAllPage = lazy(() =>
  import('../../domain/activateExternalService/containers/ActivateAllPage').then(module => ({
    default: module.ActivateAllPage,
  }))
);
const ActivateMaxPage = lazy(() =>
  import('../../domain/activateExternalService/containers/ActivateMaxPage').then(module => ({
    default: module.ActivateMaxPage,
  }))
);
const ActivationSuccessReturn = lazy(
  () => import('../../domain/activateExternalService/components/ActivationSuccessReturn')
);
const AccountPlayerSettingsPage = lazy(() =>
  import('../../domain/account/containers/PlayerSettingsPage/PlayerSettingsPage').then(module => ({
    default: module.PlayerSettingsPage,
  }))
);

// Account sub pages
const AccountInfoPage = lazy(() =>
  import('../../domain/account/containers/AccountInfoPage/AccountInfoPage').then(module => ({
    default: module.AccountInfoPage,
  }))
);
const AccountSubscriptionPage = lazy(() =>
  import('../../domain/account/containers/AccountSubscriptionPage/AccountSubscriptionPage').then(module => ({
    default: module.AccountSubscriptionPage,
  }))
);

const AccountExternalServicePage = lazy(() =>
  import('../../domain/account/containers/AccountExternalServicePage/AccountExternalServicePage').then(module => ({
    default: module.AccountExternalServicePage,
  }))
);
const AccountPaymentPage = lazy(() =>
  import('../../domain/account/containers/AccountPaymentPage/AccountPaymentPage').then(module => ({
    default: module.AccountPaymentPage,
  }))
);

const routes: AppRoutes = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.account,
    component: AccountPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.accountInfoPage,
    component: AccountInfoPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.accountSubscriptionPage,
    component: AccountSubscriptionPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
    props: { hasFooter: false },
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.accountExternalServicePage,
    component: AccountExternalServicePage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.accountPaymentPage,
    component: AccountPaymentPage,
    layout: LayoutDark,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.vippsReturnUrl,
    component: VippsReturnPage,
    layout: LayoutDark,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.changeEmail,
    component: ChangeEmailPage,
    layout: LayoutDark,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.changePhoneNumber,
    component: ChangePhoneNumberPage,
    layout: LayoutDark,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.changePassword,
    component: ChangePasswordPage,
    layout: LayoutDark,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.resetPassword,
    component: ResetPasswordPage,
    layout: LayoutDark,
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packageConfirmCancel,
    component: ConfirmCancelPackagePage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packageConfirmedPrewinbackOffer,
    component: ConfirmedPrewinbackOffer,
    layout: LayoutDark,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.stopTrialPeriodPage,
    component: StopTrialPeriodPage,
    layout: LayoutDark,
    waitForStartup: true,
    loginRequired: true,
  },
  // This MUST be before packagesChangeReceipt
  // until that component gets MUCH less
  // responsibility than currently
  // This component replaces one of the receipts
  // usually handled by PackageChangeReceipt
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packagesUpgradeReceipt,
    component: PackageUpgradeReceipt,
    layout: LayoutDarker,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packagesDowngradeReceipt,
    component: PackageDowngradeReceipt,
    layout: LayoutDarker,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packagesDowngradeAbortedReceipt,
    component: PackageDowngradeAbortedReceipt,
    layout: LayoutDarker,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packagesActivateReceipt,
    component: PackageActivatedReceipt,
    layout: LayoutDarker,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packagesCancelReceipt,
    component: PackageCancelReceipt,
    layout: LayoutDarker,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.packagesNewReceipt,
    component: PackageNewReceipt,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: ['/payment/:id', routerPaths.purchaseProducts],
    component: PaymentPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: ['/payment-method', routerPaths.paymentMethodChange],
    component: ChangePaymentMethod,
    layout: LayoutDark,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_RAW,
    path: routerPaths.externalActivationCallback,
    component: ActivationSuccessReturn,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.accountPlayerSettingsPage,
    component: AccountPlayerSettingsPage,
    layout: LayoutDarker,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.activateViaplay,
    component: ActivateViaplayPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.activateTv2,
    component: ActivateTV2PlayPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.activateTV,
    component: ActivateAllPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: ['/aktiver-hbo', routerPaths.activateMax],
    component: ActivateMaxPage,
    layout: LayoutDarker,
    waitForStartup: true,
    loginRequired: true,
  },
];

export { routes as routesAccount };
