import { create } from 'zustand';

import { getVoucherValidity } from '../../../apis/StrimGatewayAPI';
import { hasOnlyTrialDiscount, setVoucherToLocalStorage } from '../voucher.utils';

import { mapVoucherResponse } from './mappers';
import { Voucher } from './voucher.types';

export interface VoucherStore {
  isValidatingVoucher: boolean;
  validatedVoucher: undefined | Voucher;
  lastValidatedVoucher: undefined | Voucher;
  // fns
  clearVoucher: () => void;
  validateVoucherCode: (voucherCode: string) => Promise<void>;
  getValidVoucherForUser: (isPackageDiscountable: boolean, isEligibleForTrialDiscount: boolean) => Voucher | undefined;
}

export const useVoucherStore = create<VoucherStore>((set, get) => ({
  isValidatingVoucher: false,
  validatedVoucher: undefined,
  lastValidatedVoucher: undefined,
  parsedPriceVoucherDescription: null,

  validateVoucherCode: async (voucherCode: string) => {
    set(() => ({ isValidatingVoucher: true }));
    let voucher: Voucher;
    try {
      const voucherResponse = await getVoucherValidity(voucherCode);
      // Clear preloaded voucher as soon as we have one that is valid
      setVoucherToLocalStorage(undefined);
      voucher = mapVoucherResponse(voucherCode, voucherResponse);
    } catch (error) {
      voucher = {
        isValid: false,
        description: 'Noe gikk galt, vi kunne dessverre ikke legge til verdikoden.',
        code: undefined,
      };
    } finally {
      set(state => ({
        isValidatingVoucher: false,
        validatedVoucher: voucher,
        lastValidatedVoucher: voucher.isValid ? voucher : state.lastValidatedVoucher,
      }));
    }
  },

  clearVoucher: () =>
    set(() => ({
      validatedVoucher: undefined,
      lastValidatedVoucher: get().validatedVoucher?.isValid ? get().validatedVoucher : get().lastValidatedVoucher,
    })),

  getValidVoucherForUser: (isPackageDiscountable: boolean, isEligibleForTrialDiscount: boolean) => {
    const voucher = isPackageDiscountable ? get().validatedVoucher : get().lastValidatedVoucher;
    const voucherOnlyHasTrialLengthDiscount = hasOnlyTrialDiscount(voucher);

    const isValidVoucherDiscount = isEligibleForTrialDiscount || !voucherOnlyHasTrialLengthDiscount;
    return voucher?.isValid && isValidVoucherDiscount ? voucher : undefined;
  },
}));
