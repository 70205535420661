import { Swimlane } from '../../components/pages/types';
import { getTemporalProperty } from '../../components/swimlane/swimlane.utils';
import { errorTracker } from '../../utils/errorTracker/tracking';

import { SwimlaneItem } from './assetDetails.types';

type SwimlaneContext = Pick<Swimlane, 'style' | 'type'>;
type SwimlaneItemMapOptions = SwimlaneContext & { loggedIn: boolean };

type AssetSwimlaneResponse = RiksTV.ClientAPI.AssetSwimlaneResponse;
type TitleWithCard = AssetSwimlaneResponse[0];

export const mapResponseToSwimlaneAssetItem = (
  assetList: AssetSwimlaneResponse | TitleWithCard['card'][],
  swimlaneCtx: SwimlaneItemMapOptions
): SwimlaneItem[] => {
  const cardList = isTitleWithCardArray(assetList) ? assetList.map((a: TitleWithCard) => a.card) : assetList;
  return cardList
    .map(card => {
      // Filter out any item that we cannot successfully parse
      try {
        // SeriesId is the only thing missing from card that we need in certain cases
        // for instance knowing if we should route to "/serie/x" or "/film/x"
        // also used specifically by search history feature
        const seriesId =
          card._links.superCoverPage?.type === 'Series'
            ? card._links.superCoverPage.id.replace(/:\S*/g, '')
            : undefined;

        return mapToSwimlaneItem(card, swimlaneCtx, seriesId);
      } catch (error) {
        errorTracker.captureException(error, { fingerprint: ['swimlane-item-parse-error'] });
        return null;
      }
    })
    .filter((asset): asset is SwimlaneItem => asset != null);
};

export const mapToSwimlaneItem = (
  card: TitleWithCard['card'],
  swimlaneCtx: SwimlaneItemMapOptions,
  seriesId?: string
): SwimlaneItem => {
  const programState = mapCardToProgramState(card);
  return {
    // display
    id: card.tracking.elementId,
    title: card.title,
    subtitle:
      swimlaneCtx.type === 'OnTvNow'
        ? getTemporalProperty(card.labels)?.value
        : getTemporalProperty(card.subtitles)?.value,
    image: {
      url: card.image,
      bwFilter: programState.type === 'NoAccess' || programState.type === 'Expired',
    },
    description: card.description ?? '',
    imdbRating: card.imdbRating,
    rentalPrice: card.rentalPrice,
    programState,
    // other
    behaviors: card?.behaviors,
    channel: card.channel && {
      id: card.channel.id,
      logo: card.logos![0].href,
      name: card.logos![0].name,
    },
    logos: card.logos?.map(({ name, href }) => ({ name, logo: href })),
    userData: card.userData,
    links: {
      myList: card._links.myList,
      excludeFromContinueWatchingUrl: card._links?.excludeFromContinueWatching?.href,
      superCoverPage: card._links.superCoverPage,
      episodeCoverPage: card._links.episodeCoverPage,
      details: card._links.details,
      collectionLink: card._links.collectionLink,
      externalPlayback:
        card._links.externalPlayback != null && card._links.reportExternalPlayback != null
          ? {
              providerId: card._links.externalPlayback.providerId,
              playbackUrl: card._links.externalPlayback.webPlaybackUri,
              reportUrl: card._links.reportExternalPlayback.href,
            }
          : undefined,
    },
    tracking: {
      id: card.tracking.elementId,
      title: card.tracking.elementName,
      seriesId: seriesId,
    },
  };
};

export const mapCardToProgramState = (card: RiksTV.ClientAPI.SwimlaneCard): SwimlaneItem['programState'] => {
  const stateObj = getTemporalProperty(card?.states);
  if (!stateObj || !stateObj.from || !stateObj.to) {
    return { type: 'Default' };
  }
  const { value: type, from, to } = stateObj;
  switch (type) {
    case 'NoAccess':
      return { type, label: getTemporalProperty(card.labels)?.value };
    case 'Planned':
      return { type, label: getTemporalProperty(card.labels)?.value ?? '' };
    case 'Live':
      const fromEpoch = Date.parse(from);
      const progress = Date.now() - fromEpoch;
      const duration = Date.parse(to) - fromEpoch;
      const percentage = Math.round((progress / duration) * 100);
      return { type, progressPercentage: percentage, label: getTemporalProperty(card.labels)?.value };
    case 'Expired':
      return { type, label: getTemporalProperty(card.labels)?.value ?? '' };
    case 'Rented':
      return { type, label: getTemporalProperty(card.labels)?.value ?? '' };
    default:
      return { type: 'Default', label: getTemporalProperty(card.labels)?.value };
  }
};

const isTitleWithCardArray = (
  assetList: AssetSwimlaneResponse | TitleWithCard['card'][]
): assetList is AssetSwimlaneResponse => {
  if (assetList.length === 0) {
    return false;
  }

  return 'card' in assetList[0];
};
