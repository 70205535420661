import API from '@strim/gateway-api';

import { Voucher } from './voucher.types';

const mapVoucherResponse = (
  voucherCode: string | undefined,
  validateVoucherResponse: API.ValidateVoucherResponse
): Voucher => ({
  code: voucherCode?.toUpperCase(),
  ...validateVoucherResponse,
});

export { mapVoucherResponse };
