import type GW_API from '@strim/gateway-api';
import GatewayAPI, { Products } from '@strim/gateway-api';

import { getJwt } from '@rikstv/play-common/src/utils/auth/AuthService';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { delay, poll } from '@rikstv/play-common/src/utils/polling/poll';
import { isOneOf } from '@rikstv/play-common/src/utils/types/typeUtils';

import { getBillingProviderVippsStatus, getCustomerSubscriptionOperation } from '../../../apis/StrimGatewayAPI';
import { isBaseProductOnly } from '../../products/utils/productHelpers';
import userApi from '../../user/forces/api';
import { useVoucherStore } from '../../voucher/forces/voucherStore';

import type { PaymentStoreSetState } from './usePaymentStore';

export type PaymentMethod = 'card' | 'paypal' | 'vipps' | undefined;
export type BillingProviders = GW_API.Models.BillingProviderVipps | GW_API.Models.BillingProviderBraintree;

export type BillingProvider =
  | { provider: GW_API.Models.BillingProviderVipps; returnUrl: string }
  | { provider: GW_API.Models.BillingProviderBraintree; nonce: string };

export const getBillingProviderInfo = (billingProvider: BillingProvider) => {
  if (billingProvider.provider === 'Vipps') {
    return { billingProviderName: billingProvider.provider, returnUrl: billingProvider.returnUrl };
  }
  return { billingProviderName: billingProvider.provider, nonce: billingProvider.nonce };
};

const PURCHASE_TRIAL_KEY = 'strim_purchase_trial';
export const persistPurchaseTrialStatus = (isTrial: boolean, userId: string) => {
  if (isTrial) {
    sessionStorage.setItem(PURCHASE_TRIAL_KEY, JSON.stringify({ isTrial, userId, timestamp: Date.now() }));
  }
};

export const getPurchaseTrialStatus = (userId: string) => {
  const value = sessionStorage.getItem(PURCHASE_TRIAL_KEY);
  sessionStorage.removeItem(PURCHASE_TRIAL_KEY);
  if (!value) {
    return false;
  }
  try {
    const { isTrial, userId: storedUserId, timestamp } = JSON.parse(value);
    if (userId !== storedUserId) {
      return false;
    }
    const minutesAgo = (Date.now() - timestamp) / 1000 / 60;
    if (minutesAgo > 15) {
      return false;
    }
    return Boolean(isTrial);
  } catch {
    return false;
  }
};

export const getVoucherAndOperation = async (productsToBuy: Products.Product[]) => {
  const subscriptionOperation = await getCustomerSubscriptionOperation(
    getJwt(),
    productsToBuy.map(p => p.id)
  );

  const validVoucher = useVoucherStore
    .getState()
    .getValidVoucherForUser(isBaseProductOnly(productsToBuy), subscriptionOperation.isEligibleForTrialDiscount);

  return {
    validVoucher,
    subscriptionOperation,
  };
};

export const waitForVippsStatusAndRedirect = async (set: PaymentStoreSetState) => {
  const waitMs = 500;
  let vippsStatus: Partial<GatewayAPI.VippsStatusResponse> & { error?: boolean; errorMessage?: string };
  try {
    vippsStatus = await poll({
      pollingFunction: () =>
        getBillingProviderVippsStatus(getJwt()).then(res => {
          if (!res || !res.vippsConfirmationUrl) {
            return {
              vippsConfirmationUrl: undefined,
              error: true,
              errorMessage: 'Gir opp å hente Vipps-url etter for mange forsøk.',
              status: res?.status,
            };
          } else {
            return res;
          }
        }),
      condition: (res, retryNr) => {
        if (res?.vippsConfirmationUrl != null) return 'completed';
        return retryNr < 20 ? 'continue' : 'completed';
      },
      sleep: waitMs,
    });
  } catch (error) {
    captureExceptionInSentry(error, { fingerprint: ['vipps-error'] });
    vippsStatus = { vippsConfirmationUrl: undefined, error: true, errorMessage: error?.message ?? error };
  }

  if (vippsStatus.vippsConfirmationUrl) {
    // we got it! let's go to town!
    location.href = vippsStatus.vippsConfirmationUrl;
    return;
  }
  // Update status so that PayWithVipps can display an error message
  if (vippsStatus.error) {
    set(() => ({ vippsPaymentError: true }));
  }
};

export const waitForPackageStatusChange = async () => {
  await delay(500); // customer claims changed might happen in this interval
  return await poll({
    pollingFunction: () => userApi.getUserInfo(getJwt()).then(res => res.subscribedPackage?.state),
    condition: (state, retryNr) => {
      if (isOneOf(state, ['PaymentInProgress', 'UpgradeInProgress', 'PendingPaymentInfo'])) {
        return retryNr < 15 ? 'continue' : 'completed';
      }
      return 'completed';
    },
    sleep: 750,
  });
};
